import React, { useEffect, useReducer } from 'react';
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from '@mui/material';
import { Icon24UtilityChevron } from '@care/react-icons';
import { Divider } from '@care/react-component-lib';
import { useAccordionReducer, AccordionState } from '@/components/hooks/useAccordionReducer';
import { handleListYourBusinessClick } from '@/components/shared/HomePageTopNavigation/utils';
import { SxClassProps } from '@/types';
import { getAllLinks, gridElement, localCaregiversSections } from './commonFooterBlocks';
import SocialIconsDW from './SocialIconsDW';
import Disclaimer from './Disclaimer';
import FooterTabs from './FooterTabs';

const classes: SxClassProps = {
  footer: (theme) => ({
    backgroundColor: theme.palette.care?.grey[50],
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1.1),
  }),
  container: (theme) => ({
    padding: theme.spacing(0, 0.3, 0, 0.3),
  }),
  h4: (theme) => ({
    lineHeight: '22px',
    color: `${theme.palette.care?.grey[700]} !important`,
    fontSize: theme.spacing(2),
  }),
  accordionDetails: (theme) => ({
    marginTop: theme.spacing(1),
    padding: 0,
  }),
  accordionTabs: {
    padding: 0,
  },
  accordionSummary: (theme) => ({
    width: 'fit-content',
    padding: 0,
    '& .MuiButtonBase-root': {
      marginTop: theme.spacing(1),
    },
  }),
  bottomLinksContainer: {
    display: 'flex',
    padding: 0,
  },
  topLinksContainer: {
    display: 'flex',
  },
  intlCaregiversContainer: {
    '& ul': {
      display: 'block',
      columns: '4',
    },
    '& .hideLabel': {
      '& :empty': {
        display: 'none',
      },
    },
  },
  footerTextContainer: (theme) => ({
    backgroundColor: theme.palette.care?.grey[50],
    padding: theme.spacing(0, 0, 5, 0),
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.care?.grey[200],
    },
  }),
  footerText: (theme) => ({
    color: theme.palette.care?.grey[600],
    fontSize: theme.spacing(1.5),
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '50px',
  }),
  icon: (theme) => ({
    marginLeft: theme.spacing(0.5),
  }),
  disclaimerLinksContainer: (theme) => ({
    '& a': {
      color: theme.palette.care?.grey[700],
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.care?.grey[700],
      },
    },
  }),
  mx130: {
    maxWidth: '130px',
    marginRight: '35px',
    width: '100%',
  },
  mx145: {
    maxWidth: '145px',
    marginRight: '35px',
    width: '100%',
  },
  mx150: {
    maxWidth: '150px',
    marginRight: '35px',
    width: '100%',
  },
  mx100: {
    maxWidth: '100%',
    width: '100%',
  },
  mxtop8: (theme) => ({
    marginTop: theme.spacing(1),
  }),
};

const initialAccordionStates: AccordionState = {
  isPopularTopicsAccordionExpanded: true,
  isLocalCaregiversAccordionExpanded: true,
  isIntlCaregiversAccordionExpanded: true,
};

const HomePageFooterDW = () => {
  const [allAccordionStates, dispatch]: any = useReducer(
    useAccordionReducer,
    initialAccordionStates
  );

  useEffect(() => {
    dispatch({ type: 'CLOSE ALL ACCORDIONS' });
  }, []);

  const allLinks = getAllLinks({ isDesktop: true });
  const topLinksElement = (
    <Box key="topLinksElement" sx={classes.topLinksContainer}>
      {gridElement(allLinks.aboutCare, classes.mx130)}
      {gridElement(allLinks.getHelp, classes.mx150)}
      {gridElement(allLinks.discover, classes.mx100, false, handleListYourBusinessClick)}
      <SocialIconsDW />
    </Box>
  );

  const bottomLinksElement = (
    <Box sx={classes.bottomLinksContainer} key="bottomLinksElement">
      {gridElement(allLinks.forChildren, classes.mx130)}
      {gridElement(allLinks.forAdultsSeniors, classes.mx150)}
      {gridElement(allLinks.forPets, classes.mx130)}
      {gridElement(allLinks.forHome, classes.mx145)}
      {gridElement(allLinks.programsAndResources, classes.mx100)}
    </Box>
  );

  const footerAccordion = (
    <>
      {topLinksElement}
      <Accordion
        data-testid="footer-popular-topics"
        elevation={0}
        expanded={allAccordionStates.isPopularTopicsAccordionExpanded}
        onChange={() =>
          dispatch({ type: 'TOGGLE ACCORDION', key: 'isPopularTopicsAccordionExpanded' })
        }>
        <AccordionSummary
          sx={classes.accordionSummary}
          expandIcon={<Icon24UtilityChevron sx={classes.icon} color="#505D6D" />}>
          <Typography variant="h4" sx={classes.h4}>
            Popular topics
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.accordionDetails}>{bottomLinksElement}</AccordionDetails>
      </Accordion>
    </>
  );

  const footerLocalCaregiversAccordion = (
    <>
      <Accordion
        elevation={0}
        expanded={allAccordionStates.isLocalCaregiversAccordionExpanded}
        onChange={() =>
          dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalCaregiversAccordionExpanded' })
        }>
        <AccordionSummary
          sx={classes.accordionSummary}
          expandIcon={<Icon24UtilityChevron sx={classes.icon} color="#505D6D" />}>
          <Typography variant="h4" sx={classes.h4}>
            Find local caregivers
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.accordionTabs}>
          <FooterTabs tabSections={localCaregiversSections} />
        </AccordionDetails>
      </Accordion>
    </>
  );

  const footerIntlCaregiversAccordion = (
    <>
      <Accordion
        elevation={0}
        expanded={allAccordionStates.isIntlCaregiversAccordionExpanded}
        onChange={() =>
          dispatch({ type: 'TOGGLE ACCORDION', key: 'isIntlCaregiversAccordionExpanded' })
        }>
        <AccordionSummary
          sx={classes.accordionSummary}
          expandIcon={<Icon24UtilityChevron sx={classes.icon} color="#505D6D" />}>
          <Typography variant="h4" sx={classes.h4}>
            Find care around the world
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={classes.accordionDetails}>
          <Box sx={classes.intlCaregiversContainer} key="intlCaregiversElement">
            {gridElement(allLinks.internationalLinks, undefined, true)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );

  return (
    <Box component="footer" data-testid="footer" sx={classes.footer}>
      <Container maxWidth="md" disableGutters sx={classes.container}>
        {footerAccordion}
      </Container>
      <Container maxWidth="md" disableGutters sx={classes.container}>
        {footerLocalCaregiversAccordion}
      </Container>
      <Container maxWidth="md" disableGutters sx={classes.container}>
        {footerIntlCaregiversAccordion}
      </Container>
      <Box data-testid="footer-disclaimer" sx={classes.footerTextContainer}>
        <Container maxWidth="md" disableGutters>
          <Box ml={0.3} mr={0.3}>
            <Divider />
          </Box>
          <Typography variant="disclaimer2" sx={classes.footerText}>
            <Disclaimer />
          </Typography>
          <Box ml={0.3} mr={0.3} sx={classes.disclaimerLinksContainer}>
            {gridElement(allLinks.disclaimerLinks, classes.mxtop8)}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePageFooterDW;
