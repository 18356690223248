import getConfig from 'next/config';
import { Box, Grid } from '@mui/material';
import { SOCIAL_LINKS } from '@/constants';
import { SxClassProps } from '@/types';
import { handleSocialIconsClick } from './commonFooterBlocks';
import { SocialLinksShared } from './SocialLinksShared';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

const classes: SxClassProps = {
  desktopFooter: (theme) => ({
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '320px',
    },
  }),
  storeLinks: (theme) => ({
    marginBottom: theme.spacing(0.4),
    marginTop: theme.spacing(3.2),
    '& a': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  appleImg: {
    height: '38px',
    width: '117px',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  googlePlayImg: {
    height: '38px',
    width: '128px',
    overflow: 'hidden',
    '& img': {
      height: '56px',
      marginTop: '-9px',
      marginLeft: '-9px',
    },
  },
};

const SocialIconsDW = () => {
  return (
    <Box sx={classes.desktopFooter} display="flex" flexDirection="column" justifyContent="center">
      <Grid container direction="column" alignItems="flex-end" sx={classes.storeLinks}>
        <Box
          component="a"
          href={`${CZEN_GENERAL}${SOCIAL_LINKS.appStore}`}
          data-testid="appStore"
          sx={classes.appleImg}
          onClick={(e) =>
            handleSocialIconsClick(e, 'AppStore', `${CZEN_GENERAL}${SOCIAL_LINKS.appStore}`)
          }>
          <img
            src="/app/vhp/static/appStore.svg"
            alt="App Store"
            width="117"
            height="38"
            loading="lazy"
          />
        </Box>
        <Box
          component="a"
          href={`${CZEN_GENERAL}${SOCIAL_LINKS.googlePlay}`}
          data-testid="googlePlay"
          sx={classes.googlePlayImg}
          onClick={(e) =>
            handleSocialIconsClick(e, 'GooglePlay', `${CZEN_GENERAL}${SOCIAL_LINKS.googlePlay}`)
          }>
          <img
            src="/app/vhp/static/googlePlay.svg"
            alt="Google Play"
            width="145"
            height="56"
            loading="lazy"
          />
        </Box>
      </Grid>
      <SocialLinksShared />
    </Box>
  );
};

export default SocialIconsDW;
