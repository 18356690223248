export interface AccordionState {
  [key: string]: boolean;
}

export const useAccordionReducer = (
  state: AccordionState,
  action: { type: string; key: string }
) => {
  switch (action.type) {
    case 'TOGGLE ACCORDION':
      return {
        ...state,
        [action.key]: !state[action.key],
      };
    case 'CLOSE ALL ACCORDIONS':
      return {
        ...state,
        isPopularTopicsAccordionExpanded: false,
        isLocalCaregiversAccordionExpanded: false,
        isIntlCaregiversAccordionExpanded: false,
        isMoreAccordionExpanded: false,
        isAboutCareAccordionExpanded: false,
        isGetHelpAccordionExpanded: false,
        isDiscoverAccordionExpanded: false,
        isForChildrenAccordionExpanded: false,
        isForAdultsAccordionExpanded: false,
        isForPetsAccordionExpanded: false,
        isForHomeAccordionExpanded: false,
        isProgramsAccordionExpanded: false,
        isLocalByStateExpanded: false,
        isLocalBabysittersExpanded: false,
        isLocalNanniesExpanded: false,
        isLocalDaycaresExpanded: false,
        isLocalSeniorCareExpanded: false,
        isLocalPetSittersExpanded: false,
        isLocalHousekeepersExpanded: false,
        isLocalTutorsExpanded: false,
      };
    default:
      return { ...state };
  }
};
