import React from 'react';

interface TabContentPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabContentPanel = ({ children, index, value }: TabContentPanelProps) => {
  return <div hidden={value !== index}>{children}</div>;
};

export default TabContentPanel;
