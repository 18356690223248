import HomePageFooterDW from './HomePageFooterDW';
import HomePageFooterMW from './HomePageFooterMW';

type HomePageFooterProps = {
  renderDesktopView?: boolean;
};

function HomePageFooter({ renderDesktopView = false }: HomePageFooterProps) {
  return renderDesktopView ? <HomePageFooterDW /> : <HomePageFooterMW />;
}

export default HomePageFooter;
