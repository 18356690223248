import React, { useState } from 'react';
import { Grid, Tab, Tabs, Link, Box } from '@mui/material';
import { SxClassProps } from '@/types';
import TabContentPanel from './TabContentPanel';

const classes: SxClassProps = {
  tabSectionContainer: (theme) => ({
    marginBottom: theme.spacing(3),
    fontSize: '16px',
  }),
  linkCategoriesContainer: (theme) => ({
    minHeight: theme.spacing(20),
  }),
  linkCategories: {
    cursor: 'pointer',
    lineHeight: '30px',
  },
  flexContainer: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  }),
  listContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  }),
  tab: [
    (theme) => ({
      '&.MuiTab-root': {
        padding: theme.spacing(0),
      },
    }),
  ],
  tabsSectionLinks: (theme) => ({
    lineHeight: '30px',
    color: `${theme.palette.care?.grey[700]} !important`,
  }),
};

interface FooterTabsProps {
  tabSections: FooterTabsSection[];
}

interface FooterTabsSection {
  label: string;
  items: LinkList[];
}

interface LinkList {
  address: string;
  label: string;
}

const FooterTabs = ({ tabSections }: FooterTabsProps) => {
  const [value, setValue] = useState(0);

  const handleTabChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  const links = (linkList: LinkList[]) => {
    return linkList.map((link: LinkList) => (
      <Link variant="body3" sx={classes.tabsSectionLinks} href={link.address} key={link.label}>
        {link.label}
      </Link>
    ));
  };

  return (
    <Grid item xs={12}>
      {tabSections.length > 0 && (
        <>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={classes.tabSectionContainer}>
            {tabSections.map((tabSection: FooterTabsSection) => (
              <Tab label={tabSection.label} key={tabSection.label} sx={classes.tab} />
            ))}
          </Tabs>
          <Box sx={classes.linkCategoriesContainer}>
            {tabSections.map((tabSection: FooterTabsSection, index: number) => (
              <Box key={tabSection.label} sx={classes.linkCategories}>
                <TabContentPanel value={value} index={index} key={tabSection.label}>
                  {tabSection.items.length > 20 ? (
                    <Box sx={classes.flexContainer}>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(0, 13)}</Box>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(13, 26)}</Box>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(26, 39)}</Box>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(39, 52)}</Box>
                    </Box>
                  ) : (
                    <Box sx={classes.flexContainer}>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(0, 5)}</Box>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(5, 10)}</Box>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(10, 15)}</Box>
                      <Box sx={classes.listContainer}>{links(tabSection.items).slice(15, 20)}</Box>
                    </Box>
                  )}
                </TabContentPanel>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Grid>
  );
};

export default FooterTabs;
