import { useEffect, useReducer } from 'react';
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { Icon24UtilityChevron } from '@care/react-icons';

import { AccordionState, useAccordionReducer } from '@/components/hooks/useAccordionReducer';
import { handleListYourBusinessClick } from '@/components/shared/HomePageTopNavigation/utils';
import { SxClassProps } from '@/types';
import { getAllLinks, gridElement } from './commonFooterBlocks';
import SocialIconsMW from './SocialIconsMW';
import Disclaimer from './Disclaimer';

const classes: SxClassProps = {
  footer: (theme) => ({
    paddingTop: theme.spacing(5),
    backgroundColor: theme.palette.grey[100],

    '& .hideLabel': {
      '& :empty': {
        display: 'none',
      },
    },
    '& .disclaimerLinkList': {
      '& a': {
        textDecoration: 'none',
        color: `${theme.palette.care?.grey[700]} !important`,
      },
      '& span': {
        fontSize: theme.spacing(1.5),
      },
    },
  }),
  container: (theme) => ({
    padding: theme.spacing(0, 2),
  }),
  accordionDetails: {
    margin: 0,
    padding: 0,
  },
  accordianDetailsLinks: (theme) => ({
    marginLeft: theme.spacing(2),
  }),
  accordianSummary: {
    padding: 0,
    '& .MuiIconButton-root': {
      marginRight: '-7px',
    },
  },
  footerTextContainer: (theme) => ({
    padding: theme.spacing(0, 0, 5, 0),
  }),
  footerText: (theme) => ({
    color: theme.palette.care?.grey[600],
    padding: theme.spacing(0, 2),
  }),
  disclaimerLinksContainer: (theme) => ({
    '& a': {
      color: theme.palette.care?.grey[700],
      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.care?.grey[700],
      },
    },
  }),
  divider: (theme) => ({
    margin: theme.spacing(3, 2, 5, 2),
  }),
  mxtop16: (theme) => ({
    marginTop: theme.spacing(2),
  }),
};

const initialAccordionStates: AccordionState = {
  isPopularTopicsAccordionExpanded: true,
  isLocalCaregiversAccordionExpanded: true,
  isIntlCaregiversAccordionExpanded: true,
  isMoreAccordionExpanded: true,
  isAboutCareAccordionExpanded: true,
  isGetHelpAccordionExpanded: true,
  isDiscoverAccordionExpanded: true,
  isForChildrenAccordionExpanded: true,
  isForAdultsAccordionExpanded: true,
  isForPetsAccordionExpanded: true,
  isForHomeAccordionExpanded: true,
  isProgramsAccordionExpanded: true,
  isLocalByStateExpanded: true,
  isLocalBabysittersExpanded: true,
  isLocalNanniesExpanded: true,
  isLocalDaycaresExpanded: true,
  isLocalSeniorCareExpanded: true,
  isLocalPetSittersExpanded: true,
  isLocalHousekeepersExpanded: true,
  isLocalTutorsExpanded: true,
};

const HomePageFooterMW = () => {
  const [allAccordionStates, dispatch]: any = useReducer(
    useAccordionReducer,
    initialAccordionStates
  );

  useEffect(() => {
    dispatch({ type: 'CLOSE ALL ACCORDIONS' });
  }, []);

  const allLinks = getAllLinks({ isDesktop: false });
  const moreAccordion = (
    <Accordion
      elevation={0}
      expanded={allAccordionStates.isMoreAccordionExpanded}
      onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isMoreAccordionExpanded' })}>
      <AccordionSummary
        sx={classes.accordionSummary}
        expandIcon={<Icon24UtilityChevron color="#172C42" />}>
        <Typography variant="h3">More</Typography>
      </AccordionSummary>
      <AccordionDetails sx={classes.accordionDetails}>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isAboutCareAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isAboutCareAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">About Care.com</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.aboutCare, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isGetHelpAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isGetHelpAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Get help</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.getHelp, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isDiscoverAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isDiscoverAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Discover</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(
              allLinks.discover,
              classes.accordianDetailsLinks,
              true,
              handleListYourBusinessClick
            )}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );

  const popularTopicsAccordion = (
    <Accordion
      data-testid="footer-popular-topics"
      elevation={0}
      expanded={allAccordionStates.isPopularTopicsAccordionExpanded}
      onChange={() =>
        dispatch({ type: 'TOGGLE ACCORDION', key: 'isPopularTopicsAccordionExpanded' })
      }>
      <AccordionSummary
        sx={classes.accordionSummary}
        expandIcon={<Icon24UtilityChevron color="#172C42" />}>
        <Typography variant="h3">Popular topics</Typography>
      </AccordionSummary>
      <AccordionDetails sx={classes.accordionDetails}>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isForChildrenAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isForChildrenAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">For children</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.forChildren, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isForAdultsAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isForAdultsAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">For adults & seniors</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.forAdultsSeniors, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isForPetsAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isForPetsAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">For pets</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.forPets, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isForHomeAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isForHomeAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">For home & lifestyle</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.forHome, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isProgramsAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isProgramsAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Programs & resources</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.programsAndResources, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );

  const localCaregiversAccordion = (
    <Accordion
      elevation={0}
      expanded={allAccordionStates.isLocalCaregiversAccordionExpanded}
      onChange={() =>
        dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalCaregiversAccordionExpanded' })
      }>
      <AccordionSummary
        sx={classes.accordionSummary}
        expandIcon={<Icon24UtilityChevron color="#172C42" />}>
        <Typography variant="h3">Find local caregivers</Typography>
      </AccordionSummary>
      <AccordionDetails sx={classes.accordionDetails}>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalByStateExpanded}
          onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalByStateExpanded' })}>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">By State</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversByState, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalBabysittersExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalBabysittersExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Babysitters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversBabysitters, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalNanniesExpanded}
          onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalNanniesExpanded' })}>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Nannies</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversNannies, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalDaycaresExpanded}
          onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalDaycaresExpanded' })}>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Daycares</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversDaycares, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalSeniorCareExpanded}
          onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalSeniorCareExpanded' })}>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Senior Care</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversSeniorCare, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalPetSittersExpanded}
          onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalPetSittersExpanded' })}>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Pet Sitters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversPetSitters, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalHousekeepersExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalHousekeepersExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Housekeepers</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversHousekeepers, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isLocalTutorsExpanded}
          onChange={() => dispatch({ type: 'TOGGLE ACCORDION', key: 'isLocalTutorsExpanded' })}>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Tutors</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.localCaregiversTutors, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box component="footer" data-testid="footer" sx={classes.footer}>
      <Container maxWidth="md" disableGutters>
        <SocialIconsMW />
        {moreAccordion}
        {popularTopicsAccordion}
        {localCaregiversAccordion}
        <Accordion
          elevation={0}
          expanded={allAccordionStates.isIntlCaregiversAccordionExpanded}
          onChange={() =>
            dispatch({ type: 'TOGGLE ACCORDION', key: 'isIntlCaregiversAccordionExpanded' })
          }>
          <AccordionSummary
            sx={classes.accordionSummary}
            expandIcon={<Icon24UtilityChevron color="#172C42" />}>
            <Typography variant="h3">Find care around the world</Typography>
          </AccordionSummary>
          <AccordionDetails sx={classes.accordionDetails}>
            {gridElement(allLinks.internationalLinks, classes.accordianDetailsLinks, true)}
          </AccordionDetails>
        </Accordion>
      </Container>
      <Box data-testid="footer-disclaimer" sx={classes.footerTextContainer}>
        <Container maxWidth="md" disableGutters>
          <Box sx={classes.divider}>
            <Divider />
          </Box>
          <Typography variant="disclaimer2" sx={classes.footerText}>
            <Disclaimer />
          </Typography>
          <Box ml={0.3} mr={0.3} sx={classes.disclaimerLinksContainer}>
            {gridElement(allLinks.disclaimerLinks, classes.mxtop16)}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePageFooterMW;
