import getConfig from 'next/config';
import { Box, Grid } from '@mui/material';
import { Icon24UtilityChevronRight } from '@care/react-icons';
import { SOCIAL_LINKS } from '@/constants';
import { handleListYourBusinessClick } from '@/components/shared/HomePageTopNavigation/utils';
import { SxClassProps } from '@/types';
import { handleSocialIconsClick } from './commonFooterBlocks';
import { SocialLinksShared } from './SocialLinksShared';

const {
  publicRuntimeConfig: { CZEN_GENERAL },
} = getConfig();

const classes: SxClassProps = {
  link: (theme) => ({
    fontSize: '14px',
    color: theme.palette.care?.grey[900],
    textDecoration: 'none',
    marginBottom: theme.spacing(1.25),
    paddingBottom: theme.spacing(3),
    '& .MuiSvgIcon-root': {
      verticalAlign: 'sub',
    },
  }),
};

const SocialIconsMW = () => {
  return (
    <Box>
      <Grid container justifyContent="space-around">
        <Box
          component="a"
          href={`${CZEN_GENERAL}${SOCIAL_LINKS.careWork}`}
          data-testid="careWork"
          sx={classes.link}
          onClick={(e) =>
            handleSocialIconsClick(e, 'Care@Work', `${CZEN_GENERAL}${SOCIAL_LINKS.careWork}`)
          }>
          Care for business <Icon24UtilityChevronRight size="16px" />
        </Box>
        <Box
          component="a"
          href={`${CZEN_GENERAL}${SOCIAL_LINKS.listBusiness}`}
          data-testid="listBusiness"
          sx={classes.link}
          onClick={(e) => handleListYourBusinessClick(e)}>
          List your business <Icon24UtilityChevronRight size="16px" />
        </Box>
      </Grid>

      <SocialLinksShared />
    </Box>
  );
};

export default SocialIconsMW;
