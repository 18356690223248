import { Box, Grid } from '@mui/material';
import {
  Icon48SocialMediaFilledFacebook,
  Icon48SocialMediaFilledX,
  Icon48SocialMediaFilledYoutube,
  Icon48SocialMediaFilledInstagram,
} from '@care/react-icons';
import { SOCIAL_LINKS } from '@/constants';
import { SxClassProps } from '@/types';
import { handleSocialIconsClick } from './commonFooterBlocks';

const classes: SxClassProps = {
  socialContainer: {
    justifyContent: 'center',
    '@media (min-width: 960px)': {
      justifyContent: 'flex-end',
    },
  },
  icon: (theme) => ({
    padding: theme.spacing(0, 0, 0, 3),
    '@media (max-width: 960px)': {
      padding: '1 1.5',
      margin: 0,
    },
  }),
};

// eslint-disable-next-line import/prefer-default-export
export const SocialLinksShared = () => {
  return (
    <Grid container sx={classes.socialContainer}>
      <Box
        component="a"
        href={`${SOCIAL_LINKS.facebook}`}
        data-testid="facebook"
        sx={classes.icon}
        onClick={(e) => handleSocialIconsClick(e, 'Facebook', SOCIAL_LINKS.facebook)}>
        <Icon48SocialMediaFilledFacebook size="48px" />
      </Box>
      <Box
        component="a"
        href={`${SOCIAL_LINKS.twitter}`}
        data-testid="twitter"
        sx={classes.icon}
        onClick={(e) => handleSocialIconsClick(e, 'Twitter', SOCIAL_LINKS.twitter)}>
        <Icon48SocialMediaFilledX size="48px" />
      </Box>
      <Box
        component="a"
        href={`${SOCIAL_LINKS.youtube}`}
        data-testid="youtube"
        sx={classes.icon}
        onClick={(e) => handleSocialIconsClick(e, 'Youtube', SOCIAL_LINKS.youtube)}>
        <Icon48SocialMediaFilledYoutube size="48px" />
      </Box>
      <Box
        component="a"
        href={`${SOCIAL_LINKS.instagram}`}
        data-testid="instagram"
        sx={classes.icon}
        onClick={(e) => handleSocialIconsClick(e, 'Instagram', SOCIAL_LINKS.instagram)}>
        <Icon48SocialMediaFilledInstagram size="48px" />
      </Box>
    </Grid>
  );
};
